import React, { useState, useEffect } from 'react';
import projetsData from '../../containers/archives/Archives.json';
import images from '../../assets/img/projets/images';  


import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './articleSlider.css';

const ArchivesImages = () => {
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent((prevCurrent) => (prevCurrent + 1) % projetsData.pics.length);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="archive-slider">
            
            {projetsData.pics.map((pic, index) => (
                <div 
                    key={index}
                    className={index === current ? "slide active" : "slide"}
                >
                    <img 
                        src={images[pic.url]}
                        alt={pic.altText} 
                    />
                </div>
            ))}

            <div className="nav">
                {projetsData.pics.map((pic, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrent(index)} 
                        className={index === current ? "dot active" : "dot"}
                    >
                    </button>
                ))}
            </div>

        </div>
    );
}

export default ArchivesImages;
