import React from 'react';
import { ArticleSlider } from '../../components';
import WordCloud from './WordCloud'; // Assurez-vous que le chemin d'importation est correct
import './home.css';

const Home = () => {
  return (
    <>
      <div className='home'>
        <ArticleSlider />
      </div>
      <br />
    </>
  );
}

export default Home;
