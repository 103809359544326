import React from 'react';
import projetsArchive from './Projets_Archive.json';
import './archivesprojects.css';


function ArchivesProjects() {
  return (
    <div className="projects-archive">
      <h2>Archives des Projets</h2>
      <p>
        {projetsArchive.map((project, index) => (
          <span key={index}>
            {project.projet} / {project.ville} / {project.code_postal} / {project.annee}
            {index < projetsArchive.length - 1 && <br />}
          </span>
        ))}
      </p>
    </div>
  );
}

export default ArchivesProjects;
