import React from 'react'
import projetData from '../../containers/projet/projets.json';
import { useParams } from 'react-router-dom';
import MainDescription from './MainDescription';
import MainSwiper from './MainSwiper';
import MainRelatedProjects from './MainRelatedProjects';
import './MainArticle.css';

const MainArticle = () => {
  let { projectId } = useParams();

  const projet = projetData.find((p) => String(p.url) === String(projectId)); // Filtrer les données du JSON
  const descriptionText = projet.description.join(' '); // Combine tous les éléments en une chaîne de texte
  const totalLength = projet.description.reduce((acc, des) => acc + des.length, 0); // Calcul de la longueur totale des caractères
  const halfLength = totalLength / 2;
  const hasPartners = projet.partners && projet.partners.length > 0;

  let firstColumn = '';
  let secondColumn = '';
  let currentLength = 0;

  if (projet.description.length === 1) {
    // Cas où il n'y a qu'un seul élément, on divise ce texte en deux colonnes
    const middleIndex = Math.floor(descriptionText.length / 2);
    firstColumn = descriptionText.slice(0, middleIndex);
    secondColumn = descriptionText.slice(middleIndex);
  } else {
    // Répartition du texte en fonction du nombre de caractères
    projet.description.forEach(des => {
      if (currentLength + des.length <= halfLength) {
        firstColumn += des + ' ';
        currentLength += des.length;
      } else {
        secondColumn += des + ' ';
      }
    });
  }
  return (
    <div className='main-article'>
        <MainSwiper pictures={projet.SwiperLandscape} />
        <br/>
        <h2 style={{marginTop: '1px'}}>{projet.name.toUpperCase()}, {projet.location.toUpperCase()}</h2>
        <br/>
        <br/>
        <div style={{ display: 'flex', gap: '20px', marginTop: "-50px"}}>
          <div>
            <p style={{margin: 'revert', color:'black'}}>{firstColumn.trim()}</p>
          </div>
          <div>
            <p style={{margin: 'revert', color:'black', textAlign: 'end'}}>{secondColumn.trim()}</p>
          </div>
        </div>


        

        

        <div className="panel-info-container" style={{ 
          display: 'flex', 
          flexDirection: hasPartners ? 'row-reverse' : 'row', 
          gap: '20px', 
          marginTop: "2%" 
        }}>
          {hasPartners && (
            <div style={{ alignSelf: 'flex-start', textAlign: 'end' }}>
              <b>Principales entreprises</b><br />
              {projet.partners.map(partner => <div key={partner}>{partner}</div>)}
            </div>
          )}

          <div className='technical-info' style={{ 
            alignSelf: 'flex-start', 
            textAlign: 'start', 
            width: hasPartners ? 'auto' : '100%' 
          }}>
            <MainDescription projet={projet} />
          </div>
        </div>



        



        <MainRelatedProjects categories={projet.category} />
    </div>
  )
}

export default MainArticle