import React from 'react'
import './archive.css';
import { ArchivesImages, ArchivesProjects } from '../../components';



const Archive = () => {
  return (
    <div className="archive">
      <ArchivesImages/>
      <ArchivesProjects/>
    </div>
  )
}

export default Archive;
