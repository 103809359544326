import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home, Contact, Agence, Actualites, Filtre } from './containers';
import {Archive} from './containers';
import { CustomNavbar, Footer, Article, ScrollTopButton, MainArticle } from './components';
import './App.css';

class ErrorBoundary extends React.Component { constructor(props) { super(props); this.state = { hasError: false }; } static getDerivedStateFromError(error) { return { hasError: true }; } componentDidCatch(error, errorInfo) { console.error("ErrorBoundary caught an error", error, errorInfo); } render() { if (this.state.hasError) { return <h1>Something went wrong.</h1>; } return this.props.children; } }
const App = () => {
  
  return (
    <>
    <ErrorBoundary>
      <div className="container">
        {/* Barre latérale pour le contenu principal */}
        <aside className="sidebar-main">
          {/* Contenu de la barre latérale pour le contenu principal */}
        </aside>

        <section className="main-content">
          {/* Contenu principal de la page qui sera centré */}
          <CustomNavbar />

          {/* Définition des routes pour afficher les différents composants */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/agence" element={<Agence />} />
            <Route path="/actualites" element={<Actualites />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/article" element={<Article />} />
            <Route path="/projets" element={<Filtre />} />
            <Route path="/projet/:projectId" element={<MainArticle />}/>
            <Route path="/archives" element={<Archive />}
            />          
          </Routes>
          <ScrollTopButton />
          <Footer />
        </section>

        <section className="aside-content">
          {/* Barre latérale pour le contenu secondaire */}
          <aside className="sidebar-aside">
            {/* Contenu de la barre latérale pour le contenu secondaire */}
          </aside>

          {/* Affichage du composant Projet */}
          <Filtre />
        </section>
      </div>
      </ErrorBoundary>
    </>
  );
};



export default App;