import React, { useState } from 'react';

const generatePosition = (baseX, baseY, spread = 8) => ({
  x: baseX + (Math.random() - 0.5) * spread,
  y: baseY + (Math.random() - 0.5) * spread
});

const words = [
  // Niveau tout en haut
  { text: 'RENOVATION', ...generatePosition(50, 2), size: 'text-2xl', style: 'italic' },
  
  // Premier niveau
  { text: 'CHÂTEAU', ...generatePosition(75, 12), size: 'text-2xl' },
  { text: 'THEATRE', ...generatePosition(25, 12), size: 'text-2xl' },
  
  // Deuxième niveau
  { text: 'ateliers', ...generatePosition(35, 22), size: 'text-base' },
  { text: 'NEUF', ...generatePosition(65, 22), size: 'text-2xl' },
  
  // Troisième niveau
  { text: 'CINÉMAS', ...generatePosition(20, 32), size: 'text-2xl' },
  { text: 'regions', ...generatePosition(50, 32), size: 'text-base' },
  { text: 'PROGRAMMATION', ...generatePosition(80, 32), size: 'text-2xl' },
  
  // Quatrième niveau
  { text: 'PRIVÉ', ...generatePosition(30, 42), size: 'text-xl', style: 'italic' },
  { text: 'PATRIMOINE', ...generatePosition(70, 42), size: 'text-2xl' },
  
  // Zone centrale (légèrement décalée)
  // Le mot "TOUS" est géré séparément dans le JSX
  
  // Sixième niveau
  { text: 'RESTAURATION', ...generatePosition(25, 68), size: 'text-xl', style: 'italic' },
  { text: 'EXTENSION', ...generatePosition(75, 68), size: 'text-xl', style: 'italic' },
  
  // Septième niveau
  { text: 'bureaux', ...generatePosition(40, 78), size: 'text-base' },
  { text: 'MUSÉE', ...generatePosition(60, 78), size: 'text-2xl' },
  
  // Huitième niveau
  { text: 'AMENAGEMENT', ...generatePosition(30, 88), size: 'text-2xl' },
  
  // Niveau le plus bas
  { text: 'PUBLIC', ...generatePosition(20, 98), size: 'text-2xl' },
  { text: 'SALLE DE SPECTACLES', ...generatePosition(80, 98), size: 'text-base' }
];

const WordCloud = () => {
  const [hoveredWord, setHoveredWord] = useState(null);
  const randomFloat = () => 3 + Math.random() * 4;
  const randomDelay = () => Math.random() * -3;

  return (
      <div className="max-w-2xl mx-auto relative h-[1200px]" style={{height:"250px"}}> {/* Width réduite à 2xl */}
        {/* Mot central "TOUS" */}
        <div 
          className="absolute text-6xl font-bold transform -translate-x-1/2 -translate-y-1/2 hover:scale-110 transition-all duration-500"
          style={{
            left: '50%',
            top: '50%',
            zIndex: 10,
            width: 'fit-content',
            animation: 'float 5s ease-in-out infinite alternate'
          }}
        >
          TOUS
        </div>

        {/* Autres mots */}
        {words.map((word, index) => (
          <div
            key={word.text}
            className={`absolute transform hover:scale-110 transition-all duration-500 cursor-pointer
              ${word.size}
              ${hoveredWord === word.text ? 'text-blue-600' : 'text-gray-800'}`}
            style={{
              left: `${word.x}%`,
              top: `${word.y}%`,
              transform: `translate(-50%, -50%) rotate(${(Math.random() - 0.5) * 10}deg)`,
              fontStyle: word.style || 'normal',
              animation: `float${index} ${randomFloat()}s ease-in-out infinite alternate`,
              animationDelay: `${randomDelay()}s`,
              width: 'fit-content',
              opacity: hoveredWord && hoveredWord !== word.text ? 0.5 : 1
            }}
            onMouseEnter={() => setHoveredWord(word.text)}
            onMouseLeave={() => setHoveredWord(null)}
          >
            {word.text}
          </div>
        ))}

        <style jsx>{`
          @keyframes float {
            0% { transform: translate(-50%, -50%) translateY(-8px); }
            100% { transform: translate(-50%, -50%) translateY(8px); }
          }
          ${words.map((_, index) => `
            @keyframes float${index} {
              0% { transform: translate(-50%, -50%) rotate(${(Math.random() - 0.5) * 10}deg) translateY(${(Math.random() - 0.5) * 12}px) translateX(${(Math.random() - 0.5) * 10}px); }
              100% { transform: translate(-50%, -50%) rotate(${(Math.random() - 0.5) * 10}deg) translateY(${(Math.random() - 0.5) * 12}px) translateX(${(Math.random() - 0.5) * 10}px); }
            }
          `).join('\n')}
        `}</style>
      </div>
  );
};

export default WordCloud;
