import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import "./footer.css"

const Footer = () => {
  return (
    <Container fluid className="footer">
      <Row>
        <Col>
          <p className='.copyright'>© 2023 Grima Loussouarn, 71 rue Albert Dhalenne, 93400 Saint Ouen - Tous droits réservés, reproduction interdite.</p> 
        </Col>
      </Row>
    </Container>
  )
}

export default Footer