import React from 'react';
import effectif from './effectif.json';
import ouvragesData from './ouvrage.json';
import './agencepartners.css';

function AgencePartners() {
  const renderEquipeSection = (gerants, travailleurs) => {
    return (
      <div key="Equipes">
        <h2>Équipe</h2>
        <p>
          {gerants.map((item, index) => (
            <span key={`gerant-${index}`}>
              {item.Nom} {item.Expertise}
              {index < gerants.length - 1 && <br />}
            </span>
          ))}
        <br />
          {travailleurs.map((item, index) => {
            const nextItem = travailleurs[index + 1];
            if (index % 2 === 0) {
              return (
                <span key={`travailleur-${index}`}>
                  {item.Nom} {item.Poste}
                  {nextItem ? (
                    <>
                      <br />
                      {nextItem.Nom} {nextItem.Poste}
                    </>
                  ) : ''}
                  {index < travailleurs.length - 2 && <br />}
                </span>
              );
            }
            return null;
          })}
        </p>
      </div>
    );
  };

  const renderSection = (title, data, formatOptions = {}) => {
    const { excludeTitle = false, excludePoste = false, onlyNames = false, formatOuvrage = false } = formatOptions;

    return (
      <div key={title}>
        <h2>{title}</h2>
        <p>
          {data.map((item, index) => {
            let displayText;
            if (formatOuvrage) {
              displayText = item;
            } else if (onlyNames) {
              displayText = `${item.Nom}`;
            } else {
              const poste = item.Poste && !excludePoste ? ` ${item.Poste}` : '';
              displayText = `${item.Nom}${poste}`;
            }
            return (
              <span key={index}>
                {displayText}
                {index < data.length - 1 && <br />}
              </span>
            );
          })}
        </p>
      </div>
    );
  };

  // Séparation des stagiaires et partenaires
  const stagiaires = effectif['Ils ont travaillé avec nous'].filter(item => item.Poste === 'Stagiaire');
  const partenaires = effectif['Ils ont travaillé avec nous'].filter(item => item.Titre === 'Partenaire');
  const autresTravailleur = effectif['Ils ont travaillé avec nous'].filter(item => item.Poste !== 'Stagiaire' && item.Titre !== 'Partenaire');

  return (
    <div>
      {renderEquipeSection(effectif['Gérants'].map(({ Poste, Titre, ...rest }) => rest), effectif['Ils travaillent avec nous'].map(({ Titre, ...rest }) => rest))}
      {renderSection('Ils ont travaillé avec nous', autresTravailleur.map(({ Titre, ...rest }) => rest), { excludeTitle: true })}
      {renderSection('Stagiaires', stagiaires.map(({ Titre, Poste, ...rest }) => rest), { onlyNames: true })}
      {renderSection('Partenaires', partenaires.map(({ Titre, ...rest }) => rest), { excludeTitle: true })}
      {renderSection('Photographes', ouvragesData.Photographes, { formatOuvrage: true })}
      {renderSection('Maîtres d’ouvrage publics', ouvragesData.Maitres_d_ouvrage_publics, { formatOuvrage: true })}
      {renderSection('Maîtres d’ouvrage privés', ouvragesData.Maitres_d_ouvrage_prives, { formatOuvrage: true })}
    </div>
  );
}

export default AgencePartners;